// deps
import { defineMessage } from "react-intl";
import { IoPlayOutline, IoCloseOutline } from "react-icons/io5";

const PARTNERS_SEARCH_TABS_VALUE_ALL = {
  value: "all",
  fields: [],
  defaultFields: [],
  colors: null,
  metaKey: "count",
};

export const PARTNERS_SEARCH_TABS = [PARTNERS_SEARCH_TABS_VALUE_ALL];

export const PARTNERS_SEARCH_TABS_MESSAGE = defineMessage({
  defaultMessage: "{tab, select, all {Tous} other {{tab}}}",
});

/* Tri */
export const PARTNERS_SORT_VALUE_CREATED_ASC = "created_asc";
export const PARTNERS_SORT_VALUE_CREATED_DESC = "created_desc";

export const partnersSortMessage = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} other {{sort}}}",
});

export const PARTNERS_SORTS = {
  [PARTNERS_SORT_VALUE_CREATED_ASC]: {
    id: PARTNERS_SORT_VALUE_CREATED_ASC,
  },
  [PARTNERS_SORT_VALUE_CREATED_DESC]: {
    id: PARTNERS_SORT_VALUE_CREATED_DESC,
  },
};

export const PARTNERS_SORT_LIST = Object.values(PARTNERS_SORTS);

/** Booking Workflow */
export const PARTNERS_BOOKING_WORKFLOW_VALUE_EXTERNAL = "external";
export const PARTNERS_BOOKING_WORKFLOW_VALUE_COMPLETE = "complete";

export const PARTNERS_BOOKING_WORKFLOWS = {
  [PARTNERS_BOOKING_WORKFLOW_VALUE_EXTERNAL]: {
    id: PARTNERS_BOOKING_WORKFLOW_VALUE_EXTERNAL,
  },
  [PARTNERS_BOOKING_WORKFLOW_VALUE_COMPLETE]: {
    id: PARTNERS_BOOKING_WORKFLOW_VALUE_COMPLETE,
  },
};

export const PARTNERS_BOOKING_WORKFLOW_LIST = Object.values(
  PARTNERS_BOOKING_WORKFLOWS,
);

export const partnersBookingWorkflowShortMessage = defineMessage({
  defaultMessage: `{bookingWorkflow, select,
    complete {intégration compléte}
    external {intégration partielle}
    other {{bookingWorkflow}}
  }`,
});

export const partnersBookingWorkflowMessage = defineMessage({
  defaultMessage: `{bookingWorkflow, select,
    complete {partenaire en intégration compléte}
    external {partenaire en intégration partielle}
    other {{bookingWorkflow}}
  }`,
});

export const partnersBookingWorkflowDefinitionMessage = defineMessage({
  defaultMessage: `{bookingWorkflowDefinition, select,
    complete {Partenaire en intégration compléte. L'ensemble des reversements au propriétaire ainsi qu'au territoire sont intégrés dans le système de réservation}
    external {Partenaire en intégration partielle. L'ensemble des reversements au propriétaire ainsi qu'au territoire sont intégrés hors système}
    other {{bookingWorkflowDefinition}}
  }`,
});

/** Amount mode */
export const PARTNERS_AMOUNT_MODE_VALUE_P = "P";
export const PARTNERS_AMOUNT_MODE_VALUE_E = "E";

export const partnersMarkupModeMessage = defineMessage({
  defaultMessage: `{markupMode, select,
    P {Pourcentage (%)}
    E {Euros}
    other {{markupMode}}
  }`,
});

export const PARTNERS_AMOUNT_MODES = {
  [PARTNERS_AMOUNT_MODE_VALUE_P]: {
    id: PARTNERS_AMOUNT_MODE_VALUE_P,
  },
  [PARTNERS_AMOUNT_MODE_VALUE_E]: {
    id: PARTNERS_AMOUNT_MODE_VALUE_E,
  },
};

export const PARTNERS_AMOUNT_MODE_LIST = Object.values(PARTNERS_AMOUNT_MODES);

// State
export const PARTNERS_STATE_VALUE_DISABLED = "disabled";
export const PARTNERS_STATE_VALUE_ENABLED = "enabled";
export const PARTNERS_STATE_VALUE_PAUSED = "paused";

export const partnersStateMessage = defineMessage({
  defaultMessage: `{state, select,
    enabled {activé}
    disabled {désactivé}
    paused {pause}
    other {{state}}
  }`,
});

export const PARTNERS_STATES = {
  [PARTNERS_STATE_VALUE_ENABLED]: {
    id: PARTNERS_STATE_VALUE_ENABLED,
    colors: ["green.600", "green.300"],
    color: "green",
    icon: IoPlayOutline,
  },
  [PARTNERS_STATE_VALUE_DISABLED]: {
    id: PARTNERS_STATE_VALUE_DISABLED,
    colors: ["red.600", "red.300"],
    color: "red",
    icon: IoCloseOutline,
  },
};

export const PARTNERS_STATE_LIST = Object.values(PARTNERS_STATES);

export const PARTNERS_ROOM_CONFIGURATION_VALUE_ONLINE = "online";

export const PARTNERS_ROOM_CONFIGURATION_VALUE_OACK = "oack";

export const PARTNERS_ROOM_CONFIGURATION_VALUE_ON_DEMAND = "on_demand";

export const PARTNERS_ROOM_CONFIGURATION_VALUE_ALL = "all";

export const PARTNERS_ROOM_CONFIGURATION_MODES = {
  [PARTNERS_ROOM_CONFIGURATION_VALUE_ONLINE]: {
    id: PARTNERS_ROOM_CONFIGURATION_VALUE_ONLINE,
  },
  [PARTNERS_ROOM_CONFIGURATION_VALUE_OACK]: {
    id: PARTNERS_ROOM_CONFIGURATION_VALUE_OACK,
  },
  [PARTNERS_ROOM_CONFIGURATION_VALUE_ON_DEMAND]: {
    id: PARTNERS_ROOM_CONFIGURATION_VALUE_ON_DEMAND,
  },
  [PARTNERS_ROOM_CONFIGURATION_VALUE_ALL]: {
    id: PARTNERS_ROOM_CONFIGURATION_VALUE_ALL,
  },
};

export const PARTNERS_CATEGORY_VALUE_THIRD_PARTY_SOFTWARE =
  "third_party_software";

export const PARTNERS_CATEGORY_VALUE_TOURIST_OFFICE = "tourist_office";

export const PARTNERS_CATEGORY_VALUE_TRAVEL_AGENCY = "travel_agency";

export const PARTNERS_CATEGORIES = {
  [PARTNERS_CATEGORY_VALUE_THIRD_PARTY_SOFTWARE]: {
    id: PARTNERS_CATEGORY_VALUE_THIRD_PARTY_SOFTWARE,
  },
  [PARTNERS_CATEGORY_VALUE_TOURIST_OFFICE]: {
    id: PARTNERS_CATEGORY_VALUE_TOURIST_OFFICE,
  },
  [PARTNERS_CATEGORY_VALUE_TRAVEL_AGENCY]: {
    id: PARTNERS_CATEGORY_VALUE_TRAVEL_AGENCY,
  },
};

export const PARTNER_CATEGORY_LIST = Object.values(PARTNERS_CATEGORIES);

export const partnerCategoryMessage = defineMessage({
  defaultMessage: `{category, select,
    third_party_software {Logiciel tiers}
    tourist_office {Office de tourisme}
    travel_agency {Agence de voyage}
    other {{category}}
  }`,
});
